import React, {useState, useEffect, useRef} from "react";
import * as ReactBootstrap from "react-bootstrap";
import {useMediaQuery} from "react-responsive";
import videojs from 'video.js';
import RecordRTC from 'recordrtc';
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import autosize from 'autosize';
import { useTranslation } from 'react-i18next';

import Record from 'videojs-record/dist/videojs.record.js';
import 'video.js/dist/video-js.min.css';
import 'videojs-record/dist/css/videojs.record.css';
import axios from "axios";

function FeedbackForm() {
  const isSmartphone = useMediaQuery({ maxWidth: 767 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isDesktop = useMediaQuery({ minWidth: 992, maxWidth: 1200 });
  const isDesktopLarge = useMediaQuery({ minWidth: 1201 });

  let darkModeStatus = localStorage.getItem("darkmode");

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [subjectText, setSubjectText] = useState("");
  const [messageText, setMessageText] = useState("");
  const [textTopic, setTextTopic] = useState("");
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isSize, setIsSize] = useState("");
  const [isRecorded, setIsRecorded] = useState(false);
  const [recordedVideo, setRecordedVideo] = useState({});
  const [language, setLanguage] = useState("");
  //const [recording, setRecording] = useState("false");
  const [isDataPolicy, setIsDataPolicy] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const [submitForm, setSubmitForm] = useState(false);

  //let checkboxDarkMode = document.getElementById("dark-mode-switch");
  const { t, i18n } = useTranslation();

  useEffect(() => {
    /*if(darkModeStatus){
      setIsDarkMode(true)
    } else {
      setIsDarkMode(false)
    }*/

    let queryString = window.location.search;

    let urlParams = new URLSearchParams(queryString);

    let listParticipant = ["WP1","WP2","WP3","WP4","WP5","WP6","WP7","WP8","WP9","WP10","WP11","WP12"]

    let topicValue;

    if(urlParams.get('topic')){
      let paramTopic = urlParams.get('topic')
      if(listParticipant.includes(paramTopic.toUpperCase())){
        topicValue = paramTopic.toUpperCase();
      }
    } else {
      topicValue = "Default"
    }

    setTextTopic(topicValue)

    const textElement = document.querySelectorAll("textarea");
    autosize(textElement);

    let options = {
      controls: true,
      bigPlayButton: false,
      width: 360,
      height: 240,
      fluid: false,
      plugins: {
        record: {
          audio: true,
          video: true,
          audioMimeType: 'audio/webm',
          videoMimeType: 'video/webm;codecs=vp8',
          maxLength: 60,
          //debug: true
        }
      }
    };

    let player = videojs('myVideo', options, function() {
      let msg = 'Using video.js ' + videojs.VERSION +
        ' with videojs-record ' + videojs.getPluginVersion('record') +
        ' and recordrtc ' + RecordRTC.version;
      videojs.log(msg);
    });

    player.on('deviceError', function() {
      console.log('device error:', player.deviceErrorCode);
    });

    player.on('error', function(element, error) {
      console.error(error);
    });

    player.on('startRecord', function() {
      console.log('started recording!');
    });

    player.on('finishRecord', function() {
      console.log('finished recording: ', player.recordedData);
      setRecordedVideo(player.recordedData);
    });
  })

  /*const handleChangeRecording = (e) => {
    setRecording(e.target.value);

    if(e.target.value === "false"){
      setIsRecorded(false)
      setLanguage("")
    } else {
      setIsRecorded(true)
    }
  }*/

  const handleChangeLanguages = (e) => {
    setLanguage(e.target.value);

    console.log(e.target.value)

    if(e.target.value == "" || e.target.value == "false"){
      document.getElementById("gdpr").checked = false;
      setIsDataPolicy(false)
    }
  }
  const handleChangeEmail = e => setEmail(e.target.value);
  const handleChangeUsername = e => setUsername(e.target.value);
  const handleChangeSubjectText = e => setSubjectText(e.target.value);
  const handleChangeMessageText = e => setMessageText(e.target.value);
  const handleChangeDataPolicy = () => {
    setIsDataPolicy(!isDataPolicy)
  }

  /*const checkDarkModeSwitch = event => {
    if(event.target.checked){
      localStorage.setItem("darkmode", "true");
      setIsDarkMode(true);
    } else {
      localStorage.setItem("darkmode", "false");
      setIsDarkMode(false);
    }
    setIsDarkMode(!isDarkMode)
  }*/

  const validationFeedback = (e) => {
    e.preventDefault();
    // Previous: https://easier-integration.nuromedia.com/server
    // URL for the feedback form
    const Development_Environment = "https://backend.easier-integration.nuromedia.com/";
    const Test_Environment = "https://backend.easier-test.nuromedia.com/";

    const url = Test_Environment + 'feedback-form/submit';

    // Post value for validation
    const data = {
      channel: textTopic,
      name: username,
      email: encodeURIComponent(email),
      subject: subjectText,
      message: messageText, /* language == "" || language == "false" ? "" :  */
      recording_sign_language: language,
      recording_file: recordedVideo
    };

    // fetch data and validate
    axios
      .post(url, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
        },
      })
      .then((response) => {
        if(response.status === 200){
          console.log(response.data.message)

          setSubmitError(false)
          setSubmitForm(true)

          const errorText = document.querySelectorAll(".error-text");
          for (let i = 0; i < errorText.length; i++) {
            errorText[i].style.display = "none";
          }

          setTimeout(() => {
            window.location.reload();
          }, 3000)
        } else {
          console.log("Error: Access Denied");

          setSubmitError(true)

          const errorText = document.querySelectorAll(".error-text");
          for (let i = 0; i < errorText.length; i++) {
            errorText[i].style.display = "block";
          }
        }
      })
      .catch(e => {
        console.log(e)

        setSubmitError(true)
        setSubmitForm(false)

        const errorText = document.querySelectorAll(".error-text");
        for (let i = 0; i < errorText.length; i++) {
          errorText[i].style.display = "block";
        }
      })
  }

  const ValidationSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('email-invalid'))
      .required(t('email-required')),
    username: Yup.string(),
      //.required('Username is required.'),
    subject: Yup.string()
      .required(t('subject-required')),
    message: Yup.string()
      .required(t('message-required'))
  });

  return (
    <div id="easier-content">
      {/* className={darkModeStatus ? "dark-mode-scheme" : ""} */}
      <ReactBootstrap.Container fluid={true}>
        <ReactBootstrap.Row>
          {/*<ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <h2>Feedback</h2>
          </ReactBootstrap.Col>*/}
          {
            /*
              <ReactBootstrap.Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                <label className="switch">
                  <input type="checkbox" id="dark-mode-switch" />
                  //onChange={checkDarkModeSwitch}
                  <span className="slider round"></span>
                </label>
                <div>
                  Dark mode
                </div>
              </ReactBootstrap.Col>
              <ReactBootstrap.Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                <div className="easier-select">
                  <select id="sizes" name="sizes">
                    <option value="small">Small</option>
                    <option value="normal">Normal</option>
                    <option value="large">Large</option>
                  </select>
                </div>
                <div>
                  Size changer
                </div>
              </ReactBootstrap.Col>
            */
          }
        </ReactBootstrap.Row>
        <ReactBootstrap.Row>
          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <Formik
              initialValues={{
                email: '',
                password: '',
                subject: '',
                message: '',
              }}
              validationSchema={ValidationSchema}
              onSubmit={values => {
                console.log(values.text);
              }}
            >
              {({ errors, touched }) => (
                <Form id="feedback" onSubmit={validationFeedback}>
                  <ReactBootstrap.Row>
                    <ReactBootstrap.Col xs={12} sm={6} md={6} lg={6} xl={6} xxl={6} className="text-field">
                      <div>
                        <label htmlFor="username">
                          {t('Name')}
                        </label>
                      </div>
                      <div>
                        <Field type="text" className={`feedback-username`} name="username" id="username" placeholder="Enter your name" value={username} onChange={handleChangeUsername}/>
                      </div>
                      <div className="error-text">
                        {/*
                          submitError
                            ?
                            errors.username
                            :
                            null
                        */}
                      </div>
                    </ReactBootstrap.Col>
                    <ReactBootstrap.Col xs={12} sm={6} md={6} lg={6} xl={6} xxl={6} className="text-field text-field-additional">
                      <div>
                        <label htmlFor="email">
                          {t('Email')} <sup>*</sup>
                        </label>
                      </div>
                      <div>
                        <Field type="email" className={`feedback-email`} name="email" id="email" placeholder="Enter E-Mail" value={email} onChange={handleChangeEmail}/>
                      </div>
                      <div className="error-text">
                        {
                          submitError == true
                          ?
                            t('email-required')
                          :
                            null
                        }
                      </div>
                    </ReactBootstrap.Col>
                  </ReactBootstrap.Row>
                  <div>
                    <label htmlFor="subject">
                      {t('Subject')} <sup>*</sup>
                    </label>
                  </div>
                  <div>
                    <Field type="text" className={`feedback-subject`} name="subject" id="subject" placeholder="Enter your subject" value={subjectText} onChange={handleChangeSubjectText}/>
                  </div>
                  <div className="error-text">
                    {
                      submitError
                      ?
                        t('subject-required')
                      :
                        null
                    }
                  </div>
                  <div>
                    <label htmlFor="message">
                      {t('Message')} <sup>*</sup>
                    </label>
                  </div>
                  <div>
                    <Field as="textarea" className={`feedback-message`} name="message" id="message" placeholder="Enter your message" value={messageText} onChange={handleChangeMessageText} />
                    {/* disabled={language == "" || language == "false" ? false : true} */}
                  </div>
                  <div className="error-text">
                    {
                      submitError
                      ?
                        t('message-required')
                      :
                        null
                    }
                  </div>
                  {/*<div>
                    <label htmlFor="recording">
                      Do you want to record a sign message <sup>*</sup>
                    </label>
                  </div>
                  <div>
                    <Field as="select" className={"feedback-recording"} value={recording} name="recording" id="recording" onChange={handleChangeRecording}>
                      <option value="false">False</option>
                      <option value="true">True</option>
                    </Field>
                  </div>*/}
                  <div>
                    <label htmlFor="languages">
                      {t('Message_Record')} <sup>*</sup>
                    </label>
                  </div>
                  <div>
                    <div>
                      <Field as="select" className="feedback-languages" value={language} name="languages" id="languages" onChange={handleChangeLanguages}>
                        <option value="">{t('Message_language')}</option>
                        <option value="english">{t('English')} (BSL)</option>
                        <option value="german">{t('German')} (DSGS)</option>
                        <option value="french">{t('French')} (LSF)</option>
                        <option value="italian">{t('Italian')} (LIS)</option>
                        <option value="greek">{t('Greek')} (GSL)</option>
                        <option value="dutch">{t('Dutch')} (NGT)</option>
                        <option value="suizze-italian">{t('Suizze-Italian')} (LSF-CH)</option>
                        <option value="suizze-french">{t('Suizze-French')} (LIS-CH)</option>
                        <option value="suizze-german">{t('Suizze-German')} (DSGS)</option>
                        <option value="luxembourg-german">{t('Luxembourg-German')} (DSGS)</option>
                        <option value="luxembourg-french">{t('Luxembourg-French')} (LSF)</option>
                        <option value="false">{t('Message_no_record')}</option>
                      </Field>
                    </div>
                    <div className={language == "" || language == "false" ? `video-hidden` : ``}>
                      <video id="myVideo" playsInline className={`video-js vjs-default-skin`} alt={t('video-record')}></video>
                    </div>
                    <div className={language == "" || language == "false" ? `video-hidden` : ``}>
                      <div>
                        1. {t('video-one')}
                      </div>
                      <div className="video-message">
                        2. {t('video-two')}
                      </div>
                    </div>
                    <div className={language == "" || language == "false" ? `gdpr-hidden` : ``}>
                      <label className="big-label" htmlFor="gdpr">
                        GDPR <sup>*</sup>
                      </label>
                      <div className="gdpr-checkbox">
                        <input type="checkbox" name="gdpr" id="gdpr" value={isDataPolicy} onChange={handleChangeDataPolicy} />
                        <span>{t('agreement')}</span>
                      </div>
                    </div>
                  </div>
                  <div className={
                    language == "" || language == "false"
                      ? `easier-submit-button submit-top`
                      : (language != "" || language != "false") && !isDataPolicy
                        ? `easier-submit-button easier-submit-button-disabled`
                        : `easier-submit-button`
                  }>
                    <ReactBootstrap.Button type="submit">{t('Submit')}</ReactBootstrap.Button>
                  </div>
                </Form>
              )}
            </Formik>
          </ReactBootstrap.Col>
        </ReactBootstrap.Row>
        <ReactBootstrap.Row>
          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <ReactBootstrap.Modal
              show={submitForm}
              onHide={() => setSubmitForm(false)}
              size="md"
              aria-labelledby="contained-modal-title-vcenter-feedback"
              centered
            >
              <ReactBootstrap.Modal.Header closeButton>
                <ReactBootstrap.Modal.Title id="contained-modal-title-vcenter-feedback">
                  {t('form-submitted')}
                </ReactBootstrap.Modal.Title>
              </ReactBootstrap.Modal.Header>
              <ReactBootstrap.Modal.Body id="audio-record-create-post">
                <ReactBootstrap.Row>
                  <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div id="feedback-text">
                      {t('successful')}
                    </div>
                  </ReactBootstrap.Col>
                </ReactBootstrap.Row>
              </ReactBootstrap.Modal.Body>
            </ReactBootstrap.Modal>
          </ReactBootstrap.Col>
        </ReactBootstrap.Row>
      </ReactBootstrap.Container>
    </div>
  );
}

export default FeedbackForm;
